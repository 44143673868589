<template>
  <div class="p-4">
    <!-- list -->
    <candidate-list></candidate-list>

    <!-- init form -->
    <initial-registration-form></initial-registration-form>
  </div>
</template>

<script>
export default {
  name: "CandidateManagement",
  components: {
    CandidateList: () =>
      import("@/components/Registration/Candidate/CandidateList.vue"),
    InitialRegistrationForm: () =>
      import("@/components/Registration/Candidate/InitialRegistrationForm.vue"),
  },
};
</script>